import React, {useState} from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled, { css } from 'styled-components';
import axios from 'axios';
import { Purple, LightPink, LightBlue, Background, DarkGray } from '../styles/constants';
import GithubLogo from "../content/github-logo.svg"
import TwitterLogo from "../content/twitter-logo.svg"
import LinkedInLogo from "../content/linkedin-logo.svg"
import YoutubeLogo from "../content/youtube-logo.svg"

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ContactSection = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 0;

    @media only screen and (max-width: 1000px) {
      top: 10%;
    }
`;

const ContactContainer = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 80%;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 2rem 0 2rem;
    }
`;

const LeftContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 40%;

    @media only screen and (max-width: 1000px) {
      width: 100%;
  }
`
const RightContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;

    @media only screen and (max-width: 1000px) {
      width: 80%;
    }
`;

const MainTitle = styled.span`
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: .3rem;
    padding-bottom: 2.5rem;
    color: ${Purple};

    @media only screen and (max-width: 1000px) {
      padding-bottom: 0;
    }
`;

const SubTitle = styled.span`
    font-size: 1.75rem;
    letter-spacing: .1rem;
    color: ${LightBlue}
`;

const LinkIconSection = styled.section`
    display: flex;
    justify-content: space-around;    
    width: 100%;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      margin: 2rem 0 2rem 0;
    }
`;

const LogoImage = styled.img`
    width: 50px;
    height: 50px;
    padding: 1rem;
`;

const SmallInput = styled.input`
  font-size: 1.2rem;
  width: 100%;
  padding: .7rem;
  border: none;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  resize: none;
  margin-bottom: 1rem;
  background-color: ${DarkGray};
  color: ${props => props.disabled ? 'gray' : 'white'};
`;

const BigInput = styled.input`
  font-size: 1.2rem;
  width: 100%;
  padding: .7rem;
  border: none;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  resize: none;
  min-height: 13rem;
  background-color: ${DarkGray};
  color: ${props => props.disabled ? 'gray' : 'white'};
`;

const SubmitBtn = styled.button`
  box-shadow: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: .7rem;
  margin: 1rem;
  border: ${props => props.isSubmitted || props.isFailedRequest? 'none' : '1px solid black'};
  min-width: 30%;
  max-width: 50%;
  background-color: ${props => props.isSubmitted ? 'green' : props => props.isFailedRequest ? 'red' : DarkGray};
  color: ${props => props.disabled ? 'gray' : 'white'};
  transition: background-color 500ms;
  cursor: ${props => props.disabled ? 'not-allowed': 'pointer'};

  &:hover {
    background-color: ${props => props.disabled ? 'default' :  LightBlue};
    color: ${props => props.disabled ? 'default' : 'white'};
  }

  &:focus {
    outline: none;
  }
`

const ContactComponent = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isFailedRequest, setFailedRequest] = useState(false);

  const isFormValid = name && name.trim() !== "" &&
    email && email.trim() !== "" &&
    description && description.trim() !== "" && 
    emailRegex.test(email);

  const btnText = isLoading ? "Loading..." : 
    isSubmitted ? "Success! Send again?" : 
    isFailedRequest ? "Failed. Retry?" : 
    !isFormValid ? "Invalid Form" : "Submit";

  const handleSubmit = async () => {
    if (!isFormValid){
      return;
    }

    if (isSubmitted){
      setSubmitted(false);
      return;
    }

    if (isFailedRequest){
      setFailedRequest(false);
      return;
    }

    setLoading(true);
    try {
      const resp = await axios.post("https://us-central1-person-blog-gatsby.cloudfunctions.net/email-personal-blog", {
        from: email,
        subject: "undefined",
        message: description,
        name: name
      });
      if (resp.status === 200){
        setSubmitted(true);
      } else {
        setFailedRequest(true);
      }
    } catch {
      setFailedRequest(true);
    }
    setLoading(false);
  };

  return  (
    <ContactSection>
      <ContactContainer>
        <LeftContainer>
          <MainTitle>Contact Me.</MainTitle>
          <SubTitle>
            If you’d like to contact me about an opportunity, or need help with your business.
          </SubTitle>
          <LinkIconSection>
            <a href="https://linkedin.com/in/hassan-alam"
              target="_blank">
              <LogoImage
                src={LinkedInLogo}
              />
            </a>
            <a href="https://github.com/hassan-alam"
              target="_blank">
              <LogoImage
                src={GithubLogo}
              />
            </a>
            <a href="https://twitter.com"
              target="_blank">
              <LogoImage
                src={TwitterLogo}
              />
            </a>
            <a href="https://youtube.com"
              target="_blank">
              <LogoImage
                src={YoutubeLogo}
              />
            </a>
          </LinkIconSection>
        </LeftContainer>
        <RightContainer>
          <SmallInput disabled={isSubmitted || isFailedRequest} placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
          <SmallInput disabled={isSubmitted || isFailedRequest} placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
          <BigInput disabled={isSubmitted || isFailedRequest} as="textarea" placeholder="How can I help you?" value={description} onChange={e => setDescription(e.target.value)}/>
          <SubmitBtn disabled={(!isFormValid || isLoading) && !isSubmitted && !isFailedRequest} isSubmitted={isSubmitted} isFailedRequest={isFailedRequest} onClick={() => handleSubmit()}>{btnText}</SubmitBtn>
        </RightContainer>
      </ContactContainer>
    </ContactSection>
  );
};

const ContactPage = (props) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Contact" />
      <ContactComponent />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`